<template>
  <div class="main-container">
    <h1 id="sec" class="h1">Co umíme</h1>
    <ul class="ul-container">
      <li class="ul-container-li" v-for="item in json" :key="item.title">
        <div class="list-item">
          <h2 class="h2-title">
            {{ item.title }}
          </h2>
          <div class="paragraph-section-container">
            <div class="paragraph-container">
              <img src="../assets/planet.svg" alt="" />
              <p class="p-paragraph">
                {{ item.p1 }}
              </p>
            </div>
            <div class="paragraph-container">
              <img src="../assets/planet.svg" alt="" />
              <p class="p-paragraph">
                {{ item.p2 }}
              </p>
            </div>
            <div class="paragraph-container">
              <img src="../assets/planet.svg" alt="" />
              <p class="p-paragraph">
                {{ item.p3 }}
              </p>
            </div>
            <div class="paragraph-container">
              <img src="../assets/planet.svg" alt="" />
              <p class="p-paragraph">
                {{ item.p4 }}
              </p>
            </div>
          </div>
        </div>
        <img
          class="pragraph-container-img"
          v-bind:src="require('../assets/' + item.img)"
          alt=""
        />
      </li>
    </ul>
  </div>
</template>

<script>
import data from "../../public/mainSection.json";

export default {
  data() {
    return {
      json: data,
    };
  },
};
</script>

<style scoped>
.ul-container-li:nth-child(even) {
  display: flex;
  padding-bottom: 70px;
}
.ul-container-li:nth-child(odd) {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 70px;
}
.pragraph-container-img {
  display: none;
}
.p-paragraph {
  text-align: left;
  padding-left: 20px;
}
.paragraph-section-container {
  margin: 0 10px 10px 10px;
  padding-bottom: 30px;
}
.paragraph-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 5px 5px 5px;
}
ul {
  list-style-type: none;
}
.h2-title {
  font-family: Audiowide;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  padding-top: 20px;
  padding-bottom: 28px;
}
.h1 {
  font-family: Audiowide;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 41px;
  padding-top: 30px;
  margin-bottom: 40px;
  color: #ffffff;
  text-decoration: underline;
}
.list-item {
  background: #17588a;
  border-radius: 20px;
  width: clamp(260px, 70vw, 450px);
  margin: 0px 30px 40px 30px;
  color: white;
}
.main-container {
  background-color: #091436;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
}
@media only screen and (min-width: 1024px) {
  .pragraph-container-img {
    display: flex;
    width: 450px;
  }
  .h1 {
    padding-bottom: 70px;
    font-size: 50px;
  }
}
</style>
