<template>
  <div class="main">
    <div class="home">
    <div class="logo-conteiner">
      <img class="logo" alt="Vue logo" src="../assets/name-logo.svg" />
    </div>
    <HelloWorld/>
    </div>
    <MainCard/>
    <Reference/>
    <Contact/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import MainCard from "@/components/MainCard.vue";
import Reference from "@/components/Reference.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
    MainCard,
    Reference,
    Contact,
    Footer,
  },
};
</script>

<style scoped>
.logo{
  padding: 10px 0;
}
.logo-conteiner{
justify-content: center;
align-items: center;
display: flex;
width: clamp(300px, 50vh, 850px);
height: 130px;
background: linear-gradient(152.97deg, rgba(21, 21, 21, 0.2) 0%, rgba(21, 21, 21, 0) 100%);
backdrop-filter: blur(42px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 16px;
margin-top: 70px;
}
.home{
  background-image: url("../assets/wallpapers.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .home{
    justify-content: space-evenly;
}
}
</style>