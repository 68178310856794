<template>
  <div class="contact-container">
    <img class="reference-conteiner-logo" src="../assets/logo.svg" alt="" />
    <h2 class="contact-container-h2">Kontakt</h2>
    <div class="contact-container-card-wrapper">
      <div class="contact-container-card">
        <h3>Visontronic s.r.o.</h3>
        <div class="contact-container-detail">
          <h4>Nesvačily 151</h4>
          <h4>Bystřice 257 51</h4>
        </div>
        <div class="contact-container-detail">
          <h4>IČO: 09722670</h4>
          <h4>DIČ: CZ09722670</h4>
        </div>
        <div class="contact-container-detail">
          <div class="contact-container-contact">
            <img src="../assets/mail.svg" alt="" />
            <a href="mailto:info@visiontronic.cz" class="phone-email"
              ><h4>info@visiontronic.cz</h4></a
            >
          </div>
          <div class="contact-container-contact">
            <img src="../assets/phone.svg" alt="" />
            <a href="tel:+420-739-532-048" class="phone-email">
              <h4>+420 739 532 048</h4>
            </a>
          </div>
        </div>
      </div>
      <img class="contact-container-card--img" src="../assets/Messaging.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.phone-email {
  color: white;
}
.contact-container-card--img {
  display: none;
}

.contact-container-detail {
  padding-bottom: 30px;
}
h3 {
  padding: 20px 0px;
}
.contact-container-h2 {
  margin-top: 110px;
  margin-bottom: 30px;
  text-decoration: underline;
}
.reference-conteiner-logo {
  margin: -80px;
}
.contact-container-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact-container {
  background: linear-gradient(180deg, #28a8e0 0%, #091436 100%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contact-container-card {
  background: #17588a;
  border-radius: 20px;
  width: clamp(260px, 70vw, 450px);
  margin: 0px 30px 40px 30px;
  color: white;
}
@media only screen and (min-width: 1024px) {
  .contact-container-h2 {
    padding-bottom: 70px;
    font-size: 50px;
  }
  .contact-container-card-wrapper {
    display: flex;
  }
  .contact-container-card--img {
    display: inline;
    background-image: url("../assets/Spacediscovery1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
  }
  h3 {
    font-size: xx-large;
  }
  h4 {
    font-size: x-large;
  }
}
</style>
