<template>
  <div class="hero-section-container">
    <div class="glass-container">
      <h2 class="glass-container-first">I v malém týmu <br />se dají dělat</h2>
      <h1>VELKÉ</h1>
      <h2 class="glass-container-third">věci</h2>
    </div>
    <div class="astronaut"></div>
    <a href="#sec" v-smooth-scroll>
      <div class="button">Zjisti, co umíme</div>
    </a>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.glass-container {
  display: none;
}
.button {
  background: linear-gradient(
    152.97deg,
    rgba(21, 21, 21, 0.2) 0%,
    rgba(21, 21, 21, 0) 100%
  );
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
  margin-top: 60px;
  height: 50px;
  color: white;
  font-size: 30px;
  width: 300px;
}
.astronaut {
  background-image: url("../assets/Astronaut.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 320px;
  height: 320px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media only screen and (min-width: 1024px) {
  .astronaut {
    background-image: url("../assets/Spacediscovery1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 650px;
    height: 530px;
    margin-left: 120px;
  }
  .button {
    display: inline-block;
    height: 50px;
    margin-top: 0px;
    margin-bottom: 70px;
  }
  .glass-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: clamp(100px, 50vw, 350px);
    height: clamp(100px, 50vh, 350px);
    position: absolute;
    top: 16vh;
    left: 17vw;
    z-index: 1;
    margin-top: 10vh;
    background: rgba(32, 18, 18, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: white;
  }
  .glass-container-first {
    font-family: Audiowide;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 40px;
    padding-left: 2vh;
    color: #ffffff;
  }
  .glass-container-third {
    font-family: Audiowide;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 50px;
    text-align: right;
    padding-right: 2vh;
    color: #ffffff;
  }
}
</style>
