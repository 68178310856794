<template>
    <div class="reference-conteiner">
        <img class="reference-conteiner-logo" src="../assets/logo.svg" alt="">
        <h2 class="reference-conteiner-whyus"> Proč my? </h2>
        <h3 class="reference-conteiner-title">I. Jsme zkušení</h3>
        <div class="reference-conteiner-praxe">
            <img class="reference-conteiner-praxe--img_1" src="../assets/WebDevelopment2.svg" alt="">
        </div>
        <div class="reference-conteiner-footer">
            <h2>15+</h2>
            <h3>let praxe</h3>
        </div>
        <h3 class="reference-conteiner-title">II. Jsme precizní</h3>
        <div class="reference-conteiner-praxe">
            <img class="reference-conteiner-praxe--img_2" src="../assets/heart.svg" alt="">
        </div>
        <div class="reference-conteiner-footer">
            <h2>20+</h2>
            <h3>spokojených zákazníků</h3>
        </div>
        <h3 class="reference-conteiner-title">III. Jsme spolehlivý</h3>
        <div class="reference-conteiner-praxe">
            <img class="reference-conteiner-praxe--img_3" src="../assets/celebration.svg" alt="">
        </div>
        <div  class="reference-conteiner-footer">
            <h2>30+</h2>
            <h3>dokončených projektů</h3>
        </div>
    </div>
</template>

<style scoped>
.reference-conteiner-praxe--img_1{
    width: 250px;
}
.reference-conteiner-whyus{
    margin-top: 110px;
    text-decoration: underline;
}
.reference-conteiner-logo{
    margin: -80px;
}
.reference-conteiner-title{
    width: clamp(260px, 70vw, 450px);
    margin: 40px 30px 0px 30px;
    background:white;
    color: #17588a;
    height: 55px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reference-conteiner-footer{
    width: clamp(260px, 70vw, 450px);
    margin: 0px 30px 0px 30px;
    background:white;
    color: #17588a;
    border-radius: 0px 0px 20px 20px;
}
.reference-conteiner{
    background: linear-gradient(180deg, #28A8E0 0%, #091436 100%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 80px;
}
.reference-conteiner-praxe{
    background:#17588a;
    width: clamp(260px, 70vw, 450px);
    margin: 0px 30px 0px 30px;
}
@media only screen and (min-width: 1024px) {
    .reference-conteiner-whyus{
       padding-bottom: 70px;
       font-size: 50px;
   }
   .reference-conteiner{
       padding-bottom: 80px;
   }
   .reference-conteiner-title{
    width: clamp(260px, 70vw, 600px);
    margin: 40px 30px 0px 30px;
    height: 70px;
    border-radius: 20px 20px 0px 0px;
    font-size: xx-large;

}
.reference-conteiner-footer{
    width: clamp(260px, 70vw, 600px);
    margin: 0px 30px 50px 30px;
}

.reference-conteiner-praxe{
    background:#17588a;
    width: clamp(260px, 70vw, 600px);
    margin: 0px 30px 0px 30px;
    height: 400px;
}
.reference-conteiner-praxe--img_1{
    width: 500px;
}
.reference-conteiner-praxe--img_2{
    height: 400px;
}
.reference-conteiner-praxe--img_3{
    height: 400px;
}
h2{
    font-size: xx-large;
}
}

</style>