<template>
  <div>
      <img class="footer-logo" src="../assets/logo_name.svg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
.footer-logo{
    width: 200px;
    padding: 10px 0;
}
</style>